import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'

import Layout from 'components/Layout'
import SEO from 'components/SEO'
import HeaderGallery from 'components/HeaderGallery'
import TextImage from 'components/TextImage'

import s from './boutiques.module.css'

const Boutiques = ({ data }) => {
  const {
    seo: { title: seoTitle, description: seoDescription },
    acfBoutiques: { boutiquesHeader, boutiquesTextImage },
  } = data.wordpressPage
  return (
    <Layout>
      <Helmet>
        <html data-theme-color="ebb" />
      </Helmet>
      <SEO title={seoTitle} description={seoDescription} />
      <HeaderGallery data={boutiquesHeader}>
        <Link to="/stockists/locator" className={s.link}>
          Find a stockist
        </Link>
        <Link to="/wholesale" className={s.link}>
          Become a stockist
        </Link>
      </HeaderGallery>
      <div className={s.container}>
        <TextImage data={boutiquesTextImage} />
      </div>
    </Layout>
  )
}

Boutiques.propTypes = {
  data: PropTypes.shape({
    wordpressPage: PropTypes.shape({
      seo: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
      acfBoutiques: PropTypes.shape({
        boutiquesHeader: PropTypes.object,
        boutiquesTextImage: PropTypes.array,
      }),
    }),
  }),
}

export default Boutiques

export const pageQuery = graphql`
  query Boutiques {
    wordpressPage(uri: { eq: "/boutiques/" }) {
      seo {
        title
        description
      }
      acfBoutiques {
        boutiquesHeader {
          title
          description
          images {
            mobile {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 800, placeholder: NONE)
                }
              }
            }
            desktop {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 800, placeholder: NONE)
                }
              }
            }
          }
        }
        boutiquesTextImage {
          title
          description
          linkType
          link {
            target
            title
            url
          }
          stockistTitle
          linkLabel
          images {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 800, placeholder: NONE)
                }
              }
            }
          }
        }
      }
    }
  }
`
